









































































































































































import { Component, Vue } from 'vue-property-decorator';
import UserAvatar from "../user/UserAvatar.vue";
import AvatarModal from "@/components/settings/personal/AvatarModal.vue";
import { UpdateUserMutation } from '@/graphql/auth';
import UserService from '@/services/UserService';

@Component({
  components: { UserAvatar, AvatarModal },
})
export default class EmailClaimSuccess extends Vue {
  firstNameInput: string = "";
  lastNameInput: string = "";
  emailInput: string = "";
  passwordInput: string = "";

  isFirstNameFocused: boolean = false;
  isLastNameFocused: boolean = false;
  isEmailFocused: boolean = false;
  isPasswordFocused: boolean = false;
  avatarUploaded: boolean = false;

  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get firstNamePlaceholder() {
    const placeholder = this.me.first_name || 'Enter your first name';
    return this.truncateText(placeholder, 16);  
  }

  get lastNamePlaceholder() {
    const placeholder = this.me.last_name || 'Enter your last name';
    return this.truncateText(placeholder, 16);  
  }

  get emailPlaceholder() {
    const placeholder = this.me.email || 'Enter your email';
    return this.truncateText(placeholder, 16);  
  }

  get isSaveDisabled(): boolean {
    return (
      this.firstNameInput.trim() === "" &&
      this.lastNameInput.trim() === "" &&
      this.emailInput.trim() === "" &&
      this.passwordInput.trim() === ""
    );
  }

  get isEmailValid() {
    const email = this.emailInput ?? '';
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  }

  get hasAvatar() {
    if(this.$store.state.me.avatar) {
      return true;
    } else {
      return false;
    }
  }

  get isPasswordValid() {
    const password = this.passwordInput ?? '';
    return /^(?=.*[A-Z])(?=.*\d).{8,}$/.test(password);
  }

  get onboardingStep() {
    return this.$store.state.user_onboarding_number;
  }

  get showPasswordError() {
  // Show error if the password is invalid, the password input is not empty, and the field is blurred
    return !this.isPasswordValid && this.passwordInput.trim() !== '' && !this.isPasswordFocused;
  }

  get showEmailError() {
  // Show error if the email is invalid, the email input is not empty, and the field is blurred
    return !this.isEmailValid && this.emailInput.trim() !== '' && !this.isEmailFocused;
  }

  get canSaveChanges(): boolean {
  // Enable if either name is changed or avatar is uploaded
    const hasChanges = !this.isSaveDisabled || this.hasAvatar;

    // Email and password fields must be empty or valid
    const isEmailValidOrEmpty = this.emailInput.trim() === '' || this.isEmailValid;
    const isPasswordValidOrEmpty = this.passwordInput.trim() === '' || this.isPasswordValid;

    // Button should be enabled if there are changes and email/password are valid or empty
    return hasChanges && isEmailValidOrEmpty && isPasswordValidOrEmpty;
  }


  truncateText(text: string, maxLength: number): string {
    if (text.length > maxLength) {
      return text.substring(0, maxLength - 3) + '...';
    }
    return text;
  }

  openAvatarModal() {
    this.$buefy.modal.open({
      component: AvatarModal,
      props: {
        workspace_id: this.workspace.id,
      },
      width: '300px',
      parent: this,
      // @ts-ignore
      animation: 'none',
    });
  }

  async updateUser(event: MouseEvent) {
    event.stopPropagation();
    const updatedFirstName = this.firstNameInput || this.me.first_name;
    const updatedLastName = this.lastNameInput || this.me.last_name;
    const updatedEmail = this.emailInput || this.me.email;

   

    try {
      // Update user information
      const { data: userData } = await this.$apollo.mutate({
        mutation: UpdateUserMutation,
        variables: {
          id: this.me.id,
          first_name: updatedFirstName,
          last_name: updatedLastName,
          email: updatedEmail,
          country: this.me.country,
          country_code: this.me.country_code,
          city: this.me.city,
          timezone: this.me.timezone,
        },
      });

      // if (this.onboardingStep === 2) {
      //   this.$store.commit('set_user_onboarding_step', 3);
      //   this.$gtag.event('user_reached_onboarding_step_3');
      // }

      this.$store.commit('set_me', userData.updateUser);

      // If password is entered, use SetInitialPassword mutation
      if (this.passwordInput) {
        const userService = new UserService(this.$apollo);
        await userService.setInitialPassword(this.passwordInput);
      }

      this.$buefy.toast.open({
        message: 'Updated successfully',
        position: 'is-bottom-right',
        type: 'is-black',
      });

      this.$store.commit('set_claim_account_prompt', null);

      
      location.reload();
    } catch (error) {
      console.error('Failed to update user', error);
      this.$buefy.toast.open({
        message: 'Failed to update user',
        position: 'is-bottom-right',
        type: 'is-danger',
      });
    }
  }

  focusInput(refName: string) {
    const input = this.$refs[refName] as HTMLInputElement;
    if (input) {
      input.focus();
    }
  }

  handleFocus(field: string, isFocused: boolean) {
    switch(field) {
      case 'firstName':
        this.isFirstNameFocused = isFocused;
        break;
      case 'lastName':
        this.isLastNameFocused = isFocused;
        break;
      case 'email':
        this.isEmailFocused = isFocused;
        break;
      case 'password':
        this.isPasswordFocused = isFocused;
        break;
    }
  }

  closeModal() {

    if (this.onboardingStep === 2) {
      // this.$store.commit('set_user_onboarding_step', 3);
      // this.$gtag.event('user_reached_onboarding_step_3');
    }
    // @ts-ignore
    this.$parent.close() 
  }
}
