












































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import WorkspaceSidebar from "@/components/navigation/WorkspaceSidebar.vue";
import WorkspaceSwitcher from "@/components/navigation/WorkspaceSwitcher.vue";
import OnboardingNavigator from "@/components/board/onboarding/OnboardingNavigator.vue";
import UserActivation from "@/components/auth/UserActivation.vue";
import OnboardingVideoTutorial from "@/components/workspace/OnboardingVideoTutorial.vue";
import UserService from "@/services/UserService";
import EmailClaimSuccess  from "./EmailClaimSuccess.vue"



@Component({
  components: {UserActivation, OnboardingNavigator, WorkspaceSwitcher, WorkspaceSidebar},
})
export default class WorkspaceLayout extends Vue {
  mobileMenuExpanded: boolean = false;
  service: UserService | null = null;

  email: string | null = null;
  password: string | null = null;
  first_name: string | null = null;
  last_name: string | null = null;
  highlightInput: boolean = false;
  disableCount: number = 0; 
  disableClick: boolean = false; 
  showClaimEmailSidebar: boolean = false;


  get workspace() {
    return this.$store.state.workspace;
  }

  get hasRooms() {
    return this.workspace && this.workspace.boards && this.workspace.boards.length >= 1;
  }

  get isEmailValid() {
    const email = this.email ?? '';
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  }

  get me() {
    return this.$store.state.me;
  }

  get claimAccountPromptCount() {
    return this.$store.state.claim_account_prompt_count;
  }


  toggleSidebar() {
    
  }

  get onGuestAccount() {
    return this.$store.state.me.isFake;
  }

  get templateOnboarding() {
    return this.$store.state.template_onboarding;
  }

  generatePassword() {
    const length = 15;
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const allCharacters = uppercase + lowercase + numbers;
  
    // Ensure at least one of each required character type
    let password = '';
    password += uppercase.charAt(Math.floor(Math.random() * uppercase.length));
    password += lowercase.charAt(Math.floor(Math.random() * lowercase.length));
    password += numbers.charAt(Math.floor(Math.random() * numbers.length));

    // Fill the rest of the password length with random characters
    for (let i = password.length; i < length; i++) {
      password += allCharacters.charAt(Math.floor(Math.random() * allCharacters.length));
    }

    // Shuffle the password to prevent predictable patterns
    this.password = password.split('').sort(() => 0.5 - Math.random()).join('');
  }

  handleRouterViewClick() {
    if (this.disableClick) {
      return; // Prevent any action if disableMouseActions is true
    }
    
    this.highlightInput = false;
    this.disableCount++;
    
    if (this.disableCount >= 3) {
      this.disableClick = true;
    }
  }

  openEmailClaimSuccess() {
    this.$buefy.modal.open({
      component: EmailClaimSuccess,
      props: {
        email: this.email,
        first_name: this.first_name,
        last_name: this.last_name,
      },
      parent: this,
      width: 1000,
      customClass: 'claim-with-email-success',
      canCancel: false,
    });
  }

  openTutorialVideo() {
    this.$buefy.modal.open({
      component: OnboardingVideoTutorial,
      width: '1150px',
      canCancel: true,
      parent: this,
      customClass: 'video-tutorial'
    });
    this.$store.commit('set_user_onboarding_number', null);
    this.$gtag.event('user_opened_onboarding_video');
  }

  claimAccount() {
    console.log("claim triggered")
    this.service?.claimAccount(this.email, this.me.first_name, this.me.last_name, this.password).then(() => {
      this.$gtag.event('pmb_user_claimed_account_manually');
      this.$store.commit('set_auto_gen_user_data', null);
      //this.$store.commit('set_claim_account_verify', true);
      //@ts-ignore
      //this.$parent.close();
      // this.sendVerificationCode();
      this.handleSuccess();
      // if(this.clickedClaim) {
      //   this.openVerfy = true;
      //this.$events.fire('open-verification')
      // }

      // this.$nextTick(() => {
      //   if (this.$refs.p0) {
      //     this.$refs.oInput.focus();
      //   } 
      // });
    },
    
    ).catch(() => {
      // this.parseValidationError(error);
      this.$gtag.event('pmb_user_claimed_account_failed');
    }).finally(() => {
    });
  }

  handleSuccess() {
  // Your logic after successful verification
    this.$gtag.event('pmb_user_claim_account_verification_success');
    this.showClaimEmailSidebar = false;
    this.$store.commit('set_claim_account_prompt', null);
    this.$buefy.toast.open({
      message: 'You can now access your account',
      position: 'is-bottom-right',
      type: 'is-black',
    });
    this.$store.commit('set_claim_account_verify', false);
    this.$store.commit('set_currently_on_verification_code_step', false);
    this.$store.commit('set_currently_onboarding_in_room', false);
    this.openEmailClaimSuccess()
    this.$emit('account-claimed', true)

  }

  highlighClaimAccount() {
    this.highlightInput = true;
    this.$store.commit('set_claim_account_prompt', 1);

  }

  removeHighlighClaimAccount() {
    this.highlightInput = true;
  }

  focusInput() {
    // Ensure the input is referenced correctly
    setTimeout(() => {
      (this.$refs.emailInput as HTMLInputElement).focus();
    }, 1000);
   
  }

  @Watch('claimAccountPromptCount')
  onClaimAccountPromptCountChange(newCount: number) {
    if (newCount) {
      this.showClaimEmailSidebar = true;
      this.focusInput();

    }
  }

  mounted() {
    this.service = new UserService(this.$apollo);

    this.$events.listen('claim-account-highlight', () => this.highlighClaimAccount());

    this.$events.listen('trigger-claim-account', (eventData) => {
      this.email = eventData;
      this.claimAccount();
    });

    if(this.workspace && !this.hasRooms) {
      this.$router.push({name: 'create-board', params: { workspace_pid: this.$store.state.workspace.pid } })
    }

    this.generatePassword();

    if (this.claimAccountPromptCount > 0) {
      this.showClaimEmailSidebar = true;
    }

  }
}
